import React, { useRef, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import ToolSelector from "./ToolSelector";
import { Images } from "../../../assets";
import { useAddMessageMutation } from "../../../redux/chatApiSlice";
import { TOOLS } from "../../../utils/constants";
import AppDrawer from "../../common/AppDrawer";
import { useMediaQuery } from "@mui/material";

const TextInput = ({ scrollToBottom }) => {
  const isSmallScreen = useMediaQuery("(max-width: 1024px)");
  const [selectedImage, setSelectedImage] = useState(null);
  const [isToolSelectorOpen, setIsToolSelectorOpen] = useState(false);
  const [selectedTool, setSelectedTool] = useState(TOOLS[0]);
  const [nameError, setNameError] = useState("");
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");
  const imageInputRef = useRef(null);
  const [addMessage] = useAddMessageMutation();
  const nameInputRef = useRef(null);
  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedImage(file);
      e.target.value = null;
    }
  };

  const handleToolSelection = (tool) => {
    setSelectedTool(tool);
    setIsToolSelectorOpen(false);
  };

  const handleSendMessage = async () => {
    if (message.trim() && name.trim()) {
      await addMessage({
        id: "common-room",
        currentThreadId: "common-thread",
        request: message,
        requesterId: name,
        responserId: selectedTool,
      });

      setMessage("");
      setSelectedImage(null);
      scrollToBottom();
    } else {
      if (!name.trim()) {
        setNameError(true);
        nameInputRef.current.focus();
      }
    }
  };

  return (
    <div className="fixed flex flex-col left-4 right-4 lg:flex-row gap-4 items-start bottom-10 lg:left-1/2 lg:w-1/2 lg:px-10">
      <NameInput
        nameError={nameError}
        name={name}
        setNameError={setNameError}
        setName={setName}
        nameInputRef={nameInputRef}
      />
      <ImageUploadInput ref={imageInputRef} onChange={handleImageUpload} />
      <div className="w-full rounded-[20px] relative">
        <SelectedImagePreview
          selectedImage={selectedImage}
          onClose={() => setSelectedImage(null)}
        />
        <InputArea
          selectedTool={selectedTool}
          isToolSelectorOpen={isToolSelectorOpen}
          setIsToolSelectorOpen={setIsToolSelectorOpen}
          onImageUpload={() => imageInputRef.current.click()}
          message={message}
          setMessage={setMessage}
          onSendMessage={handleSendMessage}
        />
        <AppDrawer
          appDrawerProps={{
            open: isSmallScreen && isToolSelectorOpen,
            anchor: "bottom",
            closeModal: () => setIsToolSelectorOpen(false),
            drawerWidth: "100%",
          }}
          style={{
            height: "106px",
            overflow: "auto",
            backgroundColor: "transparent",
            boxShadow: "none",
          }}
          type={"swipeable"}
        >
          <SmallToolSelectorDropdown
            selectedTool={selectedTool}
            onSelectTool={handleToolSelection}
          />
        </AppDrawer>
        <ToolSelectorDropdown
          isOpen={isToolSelectorOpen}
          selectedTool={selectedTool}
          onSelectTool={handleToolSelection}
        />
      </div>
    </div>
  );
};

const NameInput = ({
  nameError,
  name,
  setName,
  setNameError,
  nameInputRef,
}) => (
  <input
    className="text-base caret-[#3C60F0] leading-[24px] font-medium h-12 font-helvetica-medium placeholder:text-[#686868] placeholder:font-medium bg-white py-3 px-6 rounded-full w-[123px]"
    style={{
      border: nameError ? "1px solid red" : "1px solid #E3E3E3",
    }}
    placeholder="Name"
    value={name}
    onChange={(e) => setName(e.target.value)}
    onBlur={(e) => setNameError(!e.target.value.trim().length ? true : false)}
    ref={nameInputRef}
  />
);

const ImageUploadInput = React.forwardRef((props, ref) => (
  <input accept="image/*" type="file" className="hidden" ref={ref} {...props} />
));

const SelectedImagePreview = ({ selectedImage, onClose }) => (
  <AnimatePresence>
    {selectedImage && (
      <motion.div
        initial={{ opacity: 0, y: 10 }}
        animate={{ opacity: 1, y: 4 }}
        exit={{ opacity: 0, y: 10 }}
        className="absolute bg-[#F4F4F4] -top-[85px] right-0 left-0 rounded-t-[20px] overflow-hidden flex"
      >
        <div className="bg-white w-[138px] lg:w-[141px] h-[100px]" />
        <div className="pl-6 pt-3">
          <div className="p-[2.34px] bg-[#DADADA] rounded-[8px] relative">
            <button onClick={onClose} className="absolute top-1 right-1">
              <img src={Images.CloseLp} className="h-3 w-3" alt="Close" />
            </button>
            <img
              src={URL.createObjectURL(selectedImage)}
              className="w-[56.72px] object-cover h-[56.72px] rounded-[6px]"
              alt="Selected"
            />
          </div>
        </div>
      </motion.div>
    )}
  </AnimatePresence>
);

const InputArea = ({
  selectedTool,
  isToolSelectorOpen,
  setIsToolSelectorOpen,
  onImageUpload,
  message,
  setMessage,
  onSendMessage,
}) => {
  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      onSendMessage();
    }
  };

  return (
    <motion.div
      className={`min-h-12 z-10 relative rounded-full overflow-hidden flex w-full`}
      style={{
        borderRadius: isToolSelectorOpen ? "20px" : "9999px",
      }}
    >
      <button
        onClick={onImageUpload}
        className="absolute z-10 left-[152px] lg:left-[166px] top-1/2 -translate-y-1/2"
      >
        <img src={Images.AttatchLp} className="h-6 w-[13.6px]" alt="Attach" />
      </button>
      <button
        className="absolute z-10 right-[8.25px] top-1/2 -translate-y-1/2"
        onClick={onSendMessage}
      >
        <img
          src={Images.SendLp}
          className="h-[30.75px] w-[30.75px]"
          alt="Send"
        />
      </button>
      <ToolSelector
        setOpen={setIsToolSelectorOpen}
        open={isToolSelectorOpen}
        selectedTool={selectedTool}
      />
      <input
        placeholder={`Message ${selectedTool}`}
        className="w-full  caret-[#3C60F0] leading-[24px] placeholder:font-helvetica-medium font-helvetica-medium placeholder:text-[#D2D4D5] text-[#686868] pl-[37.5px] lg:pl-[45px] pr-[45px] outline-none bg-[#F4F4F4]"
        value={message}
        onChange={(e) => {
          setMessage(e.target.value);
        }}
        onKeyDown={handleKeyDown}
        rows={1}
      />
    </motion.div>
  );
};

const ToolSelectorDropdown = ({ isOpen, selectedTool, onSelectTool }) => (
  <AnimatePresence>
    {isOpen && (
      <motion.div
        initial={{ opacity: 0, y: -40 }}
        animate={{ opacity: 1, y: -20 }}
        exit={{ opacity: 0, y: -40 }}
        transition={{ duration: 0.2, ease: "easeInOut" }}
        className="hidden lg:flex flex-col w-full absolute left-0 right-0"
      >
        <div className="w-full h-5 flex">
          <div className="w-1/2 bg-white h-5" />
          <div className="w-1/2 bg-[#f4f4f4] h-5" />
        </div>
        <div className="border-t flex w-full rounded-b-[20px] bg-[#F4F4F4] border-[#E3E3E3] right-0 -bottom-[28px] font-helvetica-medium font-medium text-[#686868]">
          {TOOLS.map((tool, index) => (
            <motion.p
              key={tool}
              className={`cursor-pointer w-full text-center leading-[20px] py-1  border-r border-[#E3E3E3] ${
                tool === selectedTool ? "text-[#3C60F0]" : ""
              } ${index === TOOLS.length - 1 ? "border-none" : ""}`}
              onClick={() => onSelectTool(tool)}
              whileTap={{ scale: 0.95 }}
            >
              {tool}
            </motion.p>
          ))}
        </div>
      </motion.div>
    )}
  </AnimatePresence>
);
const SmallToolSelectorDropdown = ({ isOpen, selectedTool, onSelectTool }) => (
  <div className="flex flex-col px-6 mt-1 rounded-t-[40px] bg-white font-helvetica-medium text-sm font-medium text-[#686868]">
    {TOOLS.map((tool, index) => (
      <button
        key={tool}
        className={`py-4  w-full text-center leading-[20px]  border-b border-[#E3E3E3] ${
          tool === selectedTool ? "text-[#3C60F0]" : ""
        } ${index === TOOLS.length - 1 ? "border-none" : ""}`}
        onClick={() => onSelectTool(tool)}
        whileTap={{ scale: 0.95 }}
      >
        {tool}
      </button>
    ))}
  </div>
);

export default TextInput;
