import React, { useEffect, useRef, useState } from "react";
import ReactMarkdown from "react-markdown";

import TextInput from "./TextInput";
import { useGetConversationQuery } from "../../../redux/chatApiSlice";
import { TOOLS } from "../../../utils/constants";
import LoadingDots from "./Loading";
import { Images } from "../../../assets";
import { AnimatePresence, motion } from "framer-motion";
import remarkMath from "remark-math";
import rehypeKatex from "rehype-katex";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { solarizedlight } from "react-syntax-highlighter/dist/esm/styles/prism";
import "katex/dist/katex.min.css";

const ChatBubble = ({ chat, color }) => {
  const isAi = TOOLS.includes(chat.sender);

  const renderMarkdown = (content) => {
    try {
      return (
        <ReactMarkdown
          remarkPlugins={[remarkMath]}
          rehypePlugins={[rehypeKatex]}
          components={{
            code({ node, inline, className, children, ...props }) {
              const match = /language-(\w+)/.exec(className || "");
              return !inline && match ? (
                <SyntaxHighlighter
                  style={solarizedlight}
                  language={match[1]}
                  PreTag="div"
                  {...props}
                >
                  {String(children).replace(/\n$/, "")}
                </SyntaxHighlighter>
              ) : (
                <code className={className} {...props}>
                  {children}
                </code>
              );
            },
            ol({ node, ordered, ...props }) {
              return <ol className="list-decimal pl-5 my-2" {...props} />;
            },
            ul({ node, ...props }) {
              return <ul className="list-disc pl-5 my-2" {...props} />;
            },
            li({ node, ...props }) {
              return <li className="my-1" {...props} />;
            },
            math({ value }) {
              return <div className="math-block">{value}</div>;
            },
            inlineMath({ value }) {
              return <span className="inline-math">{value}</span>;
            },
            // Add a custom component for links
            a({ node, href, children, ...props }) {
              return (
                <a
                  href={href}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-600 hover:underline"
                  {...props}
                >
                  {children}
                </a>
              );
            },
          }}
        >
          {content}
        </ReactMarkdown>
      );
    } catch (error) {
      console.error("Error rendering markdown:", error);
      console.log("Problematic content:", content);
      return <pre>{content}</pre>;
    }
  };

  return (
    <div
      className={`max-w-[87%] w-max lg:max-w-[480px] ${isAi ? "ml-auto" : ""}`}
    >
      <div
        className={`px-[18px] py-[11.25px] rounded-[27px] font-mulish text-[15.75px] leading-[20.25px] ${
          isAi ? "rounded-br-none" : `rounded-bl-none`
        } overflow-hidden`}
        style={{
          backgroundColor: isAi ? "#DADADA" : color,
        }}
      >
        {chat.message === "..." ? (
          <LoadingDots />
        ) : (
          renderMarkdown(chat.message)
        )}
      </div>
      {chat.image && (
        <div className="mt-2 h-[262px] w-[262px] ml-auto object-cover overflow-hidden p-2.5 bg-[#DADADA] rounded-[30px]">
          <img
            src={chat.image}
            alt={`${isAi ? "Chat" : "User"}`}
            className="rounded-[20px] h-[242px] w-[242px] object-cover"
          />
        </div>
      )}
      <p
        className={`font-helvetica-neue font-light text-[#68686880] text-sm tracking-[-2%] mt-1.5 ${
          isAi ? "text-right" : "text-left"
        }`}
      >
        Message by
        <span className="text-[#686868] font-helvetica-medium">
          {" "}
          {chat.sender}
        </span>
      </p>
    </div>
  );
};

const Chat = () => {
  const colors = ["#BFEDFF", "#FFF2B6", "#FFE7F1"];
  const chatContainerRef = useRef(null);
  const [showGoDown, setShowGoDown] = useState(false);
  const { data: conversation = [] } = useGetConversationQuery({
    id: "common-room",
    currentThreadId: "common-thread",
  });
  const chatEndRef = useRef(null);

  const scrollToBottom = () => {
    chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
    setTimeout(() => {
      scrollToBottom();
    });
  }, [conversation]);
  useEffect(() => {
    const handleScroll = () => {
      if (chatContainerRef.current) {
        const { scrollTop, scrollHeight, clientHeight } =
          chatContainerRef.current;
        const isAtBottom = scrollHeight - scrollTop - clientHeight < 50;
        setShowGoDown(!isAtBottom);
      }
    };

    const chatContainer = chatContainerRef.current;
    if (chatContainer) {
      chatContainer.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (chatContainer) {
        chatContainer.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);
  const goDownHandler = () => {
    chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <div
      ref={chatContainerRef}
      className="w-full overflow-y-auto h-[100svh] lg:py-24 py-16 pb-[150px]  px-6 lg:px-10 flex flex-col gap-3 bg-[#E3E3E3]"
    >
      {conversation.map((chat, index) => (
        <React.Fragment key={chat.messageId}>
          <ChatBubble
            color={colors[index % colors.length]}
            chat={{
              sender: chat.requesterId,
              message: chat.request,
              // Add image property if needed
            }}
          />
          <ChatBubble
            color={colors[index % colors.length]}
            chat={{
              sender: chat.responserId,
              message: chat.response,
            }}
          />
        </React.Fragment>
      ))}
      {/* {mockChat.map((chat, index) => (
        <ChatBubble
        key={index}
        color={colors[index % colors.length]}
        chat={chat}
        />
        ))} */}
      <AnimatePresence>
        {showGoDown && (
          <motion.button
            className="fixed bg-[#F4F4F4] h-[30px] w-[30px] rounded-full flex items-center justify-center z-20 bottom-[112px] right-4 md:right-10"
            onClick={goDownHandler}
            initial={{ scale: 0, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0, opacity: 0 }}
            transition={{ duration: 0.2 }}
            whileTap={{ scale: 0.9 }}
          >
            <img src={Images.OptionsLp} className="w-3 h-[5.62px]" />
          </motion.button>
        )}
      </AnimatePresence>
      <TextInput scrollToBottom={scrollToBottom} />
      <div ref={chatEndRef} />
    </div>
  );
};

export default Chat;
