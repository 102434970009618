import React from "react";
import { Images } from "../../../assets";
import Hero from "./hero";
import Chat from "./chat";

const LandingPage = () => {
  return (
    <div className="h-[100svh] flex w-full">
      <img
        src={Images.LandingPageBackground}
        className="w-1/2 h-full object-cover absolute top-0 right-0 left-0 bottom-0 hidden lg:block"
        alt="background"
      />
      <img
        src={Images.LandingPageBackgroundSmaller}
        className="w-full lg:hidden h-full object-cover absolute top-0 right-0 left-0 bottom-0"
        alt="background"
      />
      <Hero />
      <div className="hidden lg:block w-1/2 overflow-y-auto  bg-[#E3E3E3]">
        <Chat />
      </div>
    </div>
  );
};

export default LandingPage;
